.dashboard-container {
  min-width: 300px;
  min-height: 300px;
  display: flex;
  justify-content: center;
}

.dashboard {
  display: flex;
  flex-direction: column;
  background-color: rgba(130, 180, 236, 0.733);
  padding: 2% 5%;
  margin-top: 3%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
}

.dashboard-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(130, 180, 236, 0.733);
  padding: 2% 5%;
  margin-top: 3%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
}

.dashboard > button {
  text-decoration: none;  
  color: rgb(33, 43, 71);  
  width: 80px;
  font-size: 16px;
  font-weight: bold;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: rgb(108, 216, 108);
  margin-top: 15px;
  align-self: center;
}

.dashboard > button:hover {
  background-color: rgb(57, 204, 57);
}

.dashboard > button:focus {
  color: rgb(33, 43, 71);  
  outline: none;
  background-color: rgb(57, 204, 57);
  box-shadow: 0 0 3px 1px rgb(57, 204, 57);
}

.dashboard > button > a {
  color: rgb(33, 43, 71);  
  text-decoration: none;  
}




