.login-form {
  display: flex;
  flex-direction: column;
}

.login-fieldset {
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
  min-width: 25%;
  border-radius: 5px;
}

.login-form > div {
  display: flex;
  align-items: center;
}

.login-form > div > label {
  margin-bottom: 6px;
  margin-right: 10px;
  width: 75px;
}

.login-form > div > input {
  flex: 1;
  margin-bottom: 10px;
  height: 35px;
  padding: 5px 10px;
  border: 1px solid rgb(33, 43, 71);
  border-radius: 5px;
}

.login-form > div > input:focus {
  outline: none;
  border: 1px solid rgba(130, 180, 236, 0.733);
  box-shadow: 0 0 5px 1px rgba(130, 180, 236, 0.733);
}

.login-form > button {
  width: 80px;
  font-size: 16px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: rgb(108, 216, 108);
  margin-top: 12px;
  align-self: center;
}

.login-form > button:focus {
  outline: none;
  background-color: rgb(57, 204, 57);
  box-shadow: 0 0 3px 1px rgb(57, 204, 57);
}

.login-form > button:hover {
  background-color: rgb(57, 204, 57);
}

.login-form > span {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.login-form > span > a {
  text-decoration: none;
}

.login-form > span > a:hover {
  text-decoration: underline;
}

