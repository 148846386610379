.error {
  color: red;
}

html {
  color: rgb(33, 43, 71);
  background-color: rgb(240, 250, 255);
}


.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px double rgb(108, 216, 108);
  border-radius: 50%;
  min-width: 100px;
  min-height: 100px;
  /* animation: loading 1.8s linear infinite; */
}

/* @keyframes loading {
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);    
  }
} */

.inner-loading {
  border-radius: 50%;
  width: 50%;
  height: 50%;
  animation: inner-loading 2.0s ease-in-out infinite;
}

@keyframes inner-loading {
  0% {
    background-color: rgba(108, 216, 108, 0.185);
    box-shadow: 0 0 3px 8px rgba(108, 216, 108, 0.185);
  }
  100% {
    background-color: rgb(108, 216, 108);
    box-shadow: 0 0 3px 8px rgb(108, 216, 108);
  }
}

