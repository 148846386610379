@import url(https://fonts.googleapis.com/css?family=Ubuntu);
.app-header {
  text-align: center;
  background-color: rgba(130, 180, 236, 0.733);
  box-shadow: 0 5px 3px -3px rgba(87, 87, 87, 0.678);
}

.app-header > h1 {
  padding-top: .5%;
  margin-top: 0;
  margin-bottom: .5%;
}

.app-header > h1 > a {
  text-decoration: none;
  color: rgb(33, 43, 71);
}

.app-header > h1 > a:hover {
  color: rgb(50, 67, 114);
}


.app-header > nav {
  text-align: right;
  padding: .5%;
}
.app-header > nav > a {
  text-decoration: none;
  margin-right: 2%;
  color: rgb(33, 43, 71);
}

.app-header > nav > a:hover {
  color: rgb(50, 67, 114);
}

.logged-in {
  min-height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.logged-in > * {
  font-size: 18px;
  margin-right: 2%;
}

.logged-in > nav > a {
  color: rgb(33, 43, 71);
  text-decoration: none;
}


.registration-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.registration-fieldset {
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
  min-width: 25%;
  border-radius: 5px;
}

.registration-form > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.registration-form > div > label {
  margin-bottom: 6px;
  margin-right: 10px;
  width: 150px;
}

.registration-form > div > input {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-bottom: 10px;
  height: 35px;
  padding: 5px 10px;
  border: 1px solid rgb(33, 43, 71);
  border-radius: 5px;
}

.registration-form > div > input:focus {
  outline: none;
  border: 1px solid rgba(130, 180, 236, 0.733);
  box-shadow: 0 0 5px 1px rgba(130, 180, 236, 0.733);
}

.registration-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.registration-footer > button {
  width: 80px;
  font-size: 16px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: rgb(118, 224, 118);
  margin-top: 12px;
}

.registration-footer > button:hover {
  background-color: rgb(57, 204, 57);
}

.registration-footer > button:focus {
  outline: none;
  background-color: rgb(57, 204, 57);
  box-shadow: 0 0 3px 1px rgb(57, 204, 57);
}

.registration-footer > a {
  font-size: 12px;
  text-decoration: none;
  margin-top: 10px;
}

.registration-footer > a:hover {
  text-decoration: underline;
}



.registration-container{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.login-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.login-fieldset {
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
  min-width: 25%;
  border-radius: 5px;
}

.login-form > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.login-form > div > label {
  margin-bottom: 6px;
  margin-right: 10px;
  width: 75px;
}

.login-form > div > input {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-bottom: 10px;
  height: 35px;
  padding: 5px 10px;
  border: 1px solid rgb(33, 43, 71);
  border-radius: 5px;
}

.login-form > div > input:focus {
  outline: none;
  border: 1px solid rgba(130, 180, 236, 0.733);
  box-shadow: 0 0 5px 1px rgba(130, 180, 236, 0.733);
}

.login-form > button {
  width: 80px;
  font-size: 16px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: rgb(108, 216, 108);
  margin-top: 12px;
  -webkit-align-self: center;
          align-self: center;
}

.login-form > button:focus {
  outline: none;
  background-color: rgb(57, 204, 57);
  box-shadow: 0 0 3px 1px rgb(57, 204, 57);
}

.login-form > button:hover {
  background-color: rgb(57, 204, 57);
}

.login-form > span {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.login-form > span > a {
  text-decoration: none;
}

.login-form > span > a:hover {
  text-decoration: underline;
}


.login-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.word-table-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.word-table {
  color: rgb(33, 43, 71);
  border-collapse: collapse;
  background-color: rgb(240, 250, 255);
  border-style: inset;
}

td, th {
  border: 1px solid rgb(33, 43, 71);
  margin: none;
  padding: 3px;
}
.dashboard-container {
  min-width: 300px;
  min-height: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.dashboard {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(130, 180, 236, 0.733);
  padding: 2% 5%;
  margin-top: 3%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
}

.dashboard-loading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(130, 180, 236, 0.733);
  padding: 2% 5%;
  margin-top: 3%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
}

.dashboard > button {
  text-decoration: none;  
  color: rgb(33, 43, 71);  
  width: 80px;
  font-size: 16px;
  font-weight: bold;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: rgb(108, 216, 108);
  margin-top: 15px;
  -webkit-align-self: center;
          align-self: center;
}

.dashboard > button:hover {
  background-color: rgb(57, 204, 57);
}

.dashboard > button:focus {
  color: rgb(33, 43, 71);  
  outline: none;
  background-color: rgb(57, 204, 57);
  box-shadow: 0 0 3px 1px rgb(57, 204, 57);
}

.dashboard > button > a {
  color: rgb(33, 43, 71);  
  text-decoration: none;  
}





.learning-table-display {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.learning-table {
  text-align: center;
  color: rgb(33, 43, 71);
  border-collapse: collapse;
  background-color: rgb(240, 250, 255);
  border-style: inset;
}








.guess-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 5%;
}

.guess-form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.guess-form-row > * {
  margin-bottom: 3%;
}


.guess-form-row > input {
  width: 80%;
  height: 24px;
  padding: 2px 5px;
  -webkit-align-self: center;
          align-self: center;
  border: 1px solid rgb(33, 43, 71);
  border-radius: 5px;
}

.guess-form > input:focus {
  outline: none;
  border: 1px solid rgba(130, 180, 236, 0.733);
  box-shadow: 0 0 5px 1px rgba(130, 180, 236, 0.733);
}

.guess-form-row > button {
  width: 80px;
  margin: 5% 0;
  font-size: 16px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: rgb(108, 216, 108);
  -webkit-align-self: center;
          align-self: center;
}

.guess-form-row > button:focus {
  outline: none;
  background-color: rgb(57, 204, 57);
  box-shadow: 0 0 3px 1px rgb(57, 204, 57);
}

.guess-form-row > button:hover {
  background-color: rgb(57, 204, 57);
  cursor: pointer;
}

.learn-word-container {
  min-width: 300px;
  min-height: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.learn-word {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(130, 180, 236, 0.733);
  padding: 2% 5%;
  margin-top: 3%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733);  
}

.learn-word > p {
  margin: 0;
}

.curr-word {
  font-size: 24px;
}

.answer-display {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(130, 180, 236, 0.733);
  padding: 2% 5%;
  margin-top: 3%;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 1px rgba(87, 87, 87, 0.733); 
}

.answer-display > button {
  width: 80px;
  margin: 5% 0;
  font-size: 16px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: rgb(108, 216, 108);
  -webkit-align-self: center;
          align-self: center;
}

.answer-display > button:focus {
  outline: none;
  background-color: rgb(57, 204, 57);
  box-shadow: 0 0 3px 1px rgb(57, 204, 57);
}

.answer-display > button:hover {
  background-color: rgb(57, 204, 57);
  cursor: pointer;
}

.answer-correct {
  font-size: 36px;
}

.answer-incorrect {
  font-size: 18px;
}
.error {
  color: red;
}

html {
  color: rgb(33, 43, 71);
  background-color: rgb(240, 250, 255);
}


.loading {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 10px double rgb(108, 216, 108);
  border-radius: 50%;
  min-width: 100px;
  min-height: 100px;
  /* animation: loading 1.8s linear infinite; */
}

/* @keyframes loading {
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);    
  }
} */

.inner-loading {
  border-radius: 50%;
  width: 50%;
  height: 50%;
  -webkit-animation: inner-loading 2.0s ease-in-out infinite;
          animation: inner-loading 2.0s ease-in-out infinite;
}

@-webkit-keyframes inner-loading {
  0% {
    background-color: rgba(108, 216, 108, 0.185);
    box-shadow: 0 0 3px 8px rgba(108, 216, 108, 0.185);
  }
  100% {
    background-color: rgb(108, 216, 108);
    box-shadow: 0 0 3px 8px rgb(108, 216, 108);
  }
}

@keyframes inner-loading {
  0% {
    background-color: rgba(108, 216, 108, 0.185);
    box-shadow: 0 0 3px 8px rgba(108, 216, 108, 0.185);
  }
  100% {
    background-color: rgb(108, 216, 108);
    box-shadow: 0 0 3px 8px rgb(108, 216, 108);
  }
}


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}

