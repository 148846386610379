
.word-table-container {
  display: flex;
  justify-content: center;
}

.word-table {
  color: rgb(33, 43, 71);
  border-collapse: collapse;
  background-color: rgb(240, 250, 255);
  border-style: inset;
}

td, th {
  border: 1px solid rgb(33, 43, 71);
  margin: none;
  padding: 3px;
}